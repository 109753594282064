<template>
  <v-container fluid class="pt-0">
    <MainModal
        :main="{ component: 'OrganizationContact', title: 'Контактна інформація' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_contact_information)"
        :modal="show_contact_information_dialog"
        @updateItemModal="contactInformationUpdateItemModal"
    />
    <v-row justify="center">
      <v-col cols="12">
        <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Список контактної інформації
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="openContactInformationCreateDialog"
                         v-bind="attrs"
                         v-on="on"
                         class="grey lighten-4 mr-1">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Створити нову контактну інформацію</span>
              </v-tooltip>
            </v-toolbar>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :headers="tableHeader"
              :items="items"
              :single-select="true"
              :show-select="selectable"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],

                }"
              class="custom-table custom-table-1"
              @click:row="onContactInformationItemClick"
              @item-selected="selectRow"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon size="24" class="mr-2" :color="getContactIcon(item.contact_type, 'color')">
                {{ getContactIcon(item.contact_type, 'icon') }}
              </v-icon>
            </template>

            <template v-slot:item.create_date="{ item }">
              <span>
                {{ item.create_date | formatDate }}
              </span>
            </template>
            <template v-slot:item.show_in_blank="{ item }">
              <span>
                <v-simple-checkbox disabled :value="item.show_in_blank"/>
              </span>
            </template>
            <template v-slot:item.show_in_bill="{ item }">
              <span>
                <v-simple-checkbox disabled :value="item.show_in_bill"/>
              </span>
            </template>
            <template v-slot:item.show_on_site="{ item }">
              <span>
                <v-simple-checkbox disabled :value="item.show_on_site"/>
              </span>
            </template>
            <template v-slot:item.other_contact="{ item }">
              <span>
                <v-simple-checkbox disabled :value="item.other_contact"/>
              </span>
            </template>
            <template v-slot:item.contact_type="{ item }">
              <span>
                {{ getContactType(item.contact_type) }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {GET_ORGANIZATION_CONTACT} from "@/store/actions/organization_contact";
import {contactTypeSelect, getContactIcon} from "@/utils/icons"

export default {
  props: ['selectable', 'model'],
  name: "OrganizationContactView",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getOrganizationContacts'
    })
  },
  data() {
    return {
      contactTypeSelect,
      selected: [],
      tableHeader: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Вид', value: 'contact_type' },
        { text: 'Значення', value: 'contact_value' },
        { text: 'Представлення', value: 'print_name' },
        { text: 'Фізична особа', value: 'nature_name' },
        { text: 'У бланк', value: 'show_in_blank' },
        { text: 'У рахунок', value: 'show_in_bill' },
        { text: 'На сайт', value: 'show_on_site' },
        { text: 'Сторонній', value: 'other_contact' },
      ],
      selected_contact_information: {},
      show_contact_information_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetch: GET_ORGANIZATION_CONTACT
    }),
    getContactIcon,
    getContactType(name) {
      const obj = this.contactTypeSelect.find(item => item.value === name)
      return obj?.text ? obj.text : 'Невідомий'
    },
    selectRow(payload) {
      this.$emit('selectedItemRow', payload.value ? payload.item : undefined)
    },
    contactInformationUpdateItemModal() {
      this.show_contact_information_dialog = false
      this.selected_contact_information = {}
    },
    openContactInformationCreateDialog() {
      this.selected_contact_information = {}
      this.show_contact_information_dialog = true
    },
    onContactInformationItemClick(payload) {
      this.selected_contact_information = JSON.parse(JSON.stringify(payload))
      this.show_contact_information_dialog = true
    },
  },
  watch: {
    model(payload) {
      if (payload) {
        this.selected = []
        this.fetch()
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped>

</style>